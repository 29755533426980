<template>
  <div>
    <div class='mb-4 flex leading-10'>
      <label class='inline-block w-1/6'>재무년도</label>
      <select :disabled='!actionAdd' v-model='drilldownFinanceInformationYear' class='form-select'>
        <option v-for='year in years' :key='`year-${year}`'>{{ year }}</option>
      </select>
    </div>
    <div class='mb-4 flex leading-10'>
      <label class='inline-block w-1/6'>분기</label>
      <select :disabled='!actionAdd' v-model='drilldownFinanceInformationQuarter' class='form-select'>
        <option value='1'>1</option>
        <option value='2'>2</option>
        <option value='3'>3</option>
        <option value='4'>4</option>
      </select>
    </div>
    <div class='mb-4 flex leading-10'>
      <label class='inline-block w-1/6'>자산</label>
      <vue-numeric
        v-model='drilldownFinanceInformationAssets'
        output-type='number'
        class='form-input w-1/4 text-right'
        :minus='true'
        separator=',' />
    </div>
    <div class='mb-4 flex leading-10'>
      <label class='inline-block w-1/6'>부채</label>
      <vue-numeric
        v-model='drilldownFinanceInformationLiabilities'
        output-type='number'
        class='form-input w-1/4 text-right'
        :minus='true'
        separator=',' />
    </div>
    <div class='mb-4 flex leading-10'>
      <label class='inline-block w-1/6'>자본</label>
      <vue-numeric
        v-model='drilldownFinanceInformationCapital'
        output-type='number'
        class='form-input w-1/4 text-right'
        :minus='true'
        separator=',' />
    </div>
    <div class='mb-4 flex leading-10'>
      <label class='inline-block w-1/6'>매출액</label>
      <vue-numeric
        v-model='drilldownFinanceInformationSales'
        output-type='number'
        class='form-input w-1/4 text-right'
        :minus='true'
        separator=',' />
    </div>
    <div class='mb-4 flex leading-10'>
      <label class='inline-block w-1/6'>영업이익</label>
      <vue-numeric
        v-model='drilldownFinanceInformationOperatingProfit'
        output-type='number'
        class='form-input w-1/4 text-right'
        :minus='true'
        separator=',' />
    </div>
    <div class='mb-4 flex leading-10'>
      <label class='inline-block w-1/6'>당기순이익</label>
      <vue-numeric
        v-model='drilldownFinanceInformationNetProfit'
        output-type='number'
        class='form-input w-1/4 text-right'
        :minus='true'
        separator=',' />
    </div>

  </div>
</template>

<script>
import { mapFields }  from 'vuex-map-fields'
import { mapGetters } from 'vuex'

export default {
  name: 'FinanceInformationEditField',
  props: [
    'actionAdd'
  ],
  data () {
    return {
      years: ['2018','2019','2020','2021','2022','2023','2024','2025', '2026','2027','2028','2029','2030','2031', '2032']
    }
  },
  computed: {
    ...mapGetters('entities', [
      'drilldownEntityId',
    ]),
    ...mapFields('financeInformations', {
      drilldownFinanceInformationEntityId: 'drilldownFinanceInformation.entity_id',
      drilldownFinanceInformationYear: 'drilldownFinanceInformation.year',
      drilldownFinanceInformationQuarter: 'drilldownFinanceInformation.quarter',
      drilldownFinanceInformationAssets: 'drilldownFinanceInformation.assets',
      drilldownFinanceInformationCapital: 'drilldownFinanceInformation.capital',
      drilldownFinanceInformationLiabilities: 'drilldownFinanceInformation.liabilities',
      drilldownFinanceInformationSales: 'drilldownFinanceInformation.sales',
      drilldownFinanceInformationOperatingProfit: 'drilldownFinanceInformation.operating_profit',
      drilldownFinanceInformationNetProfit: 'drilldownFinanceInformation.net_profit',
    }),
  },
  mounted () {
    this.drilldownFinanceInformationEntityId = this.drilldownEntityId
  }
}
</script>
