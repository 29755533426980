<template>
  <div>
    <portal :to='titleLocation'>
      <h1 class='text-2xl font-semibold px-8 py-8'>재무정보추가</h1>
    </portal>
    <component :is='financeInformationEditField' :actionAdd='actionAdd'/>
    <portal :to='actionButtonsLocation'>
      <div class='flex flex-row justify-between w-full bg-gray-100 px-8 pr-8 py-4 text-sm shadow-lg'>
        <button 
          v-if='addingFinanceInformation'
          class='border px-6 h-12 text-sm text-white rounded-md font-bold' 
          style='background-color: #223645;'
          @click='createNewFinanceInformation'>추가</button>
        <button
          v-else 
          class='border px-6 h-12 text-sm text-white rounded-md font-bold' 
          style='background-color: #223645;'
          @click='submitEditFinanceInformation'>수정</button>
        <div v-if='canDelete'>
          <button
            name='show-delete-btn'
            class='p-3 text-red-700
            text-sm border border-transparent hover:border-red-400 rounded-md'
            @click='deleteFiannceInformation'>Delete <span class='font-semibold'>{{drilldownFinanceInformationName}}</span></button>
        </div>
      </div>
    </portal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import FinanceInformationEditField from '@/views/entities/FinanceInformationEditField.vue'

export default {
  name: 'FinanceInformationEdit',
  components: {
    FinanceInformationEditField,
  },
  props: [
    'actionAdd'
  ],
  data () {
    return {
      showDelete: true,
    }
  },
  computed: {
    ...mapGetters('financeInformations', [
      'drilldownFinanceInformationId',
      'drilldownFinanceInformationName',
    ]),
    ...mapGetters('entities', [
      'drilldownEntityId',
    ]),
    canDelete () {
      return !this.actionAdd && this.showDelete
    },
    addingFinanceInformation () {
      return this.actionAdd
    },
    financeInformationEditField () {
      return 'finance-information-edit-field'
    },
    actionButtonsLocation () {
      return 'modal-footer'
    },
    titleLocation () {
      return 'modal-header'
    }
  },
  methods: {
    ...mapActions('financeInformations', [
      'deleteFinanceInformation',
      'updateFinanceInformation',
      'createFinanceInformation',
    ]),
    createNewFinanceInformation () { 
      this.createFinanceInformation().then(resp => {
        this.doneEditing(resp)
      })
    },
    deleteFiannceInformation () {
      var deleteConfirm = confirm(this.drilldownFinanceInformationName + ' 재무정보가 삭제됩니다')
      if (!deleteConfirm) {
        alert('재무정보 삭제가 취소되었습니다.')
        return
      }
      this.deleteFinanceInformation(this.drilldownFinanceInformationId).then(() => {
        this.doneEditing()
      })
      this.showDelete = false
    },
    doneEditing (resp) {
      this.$emit('done-editing', resp)
    },
    submitEditFinanceInformation () {
      this.updateFinanceInformation().then(() => {
        this.doneEditing()
      })
    },
  },
}
</script>
